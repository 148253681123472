<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-eye-check-outline
      </v-icon>
      <b>{{ 'title.audioReview'.translate() }}</b>
    </HeaderCard>

    <v-card-text>
      <v-container grid-list-xl>
        <v-layout
          v-if="isLoading"
          wrap
          align-center
          justify-center
        >
          <v-progress-circular
            :size="55"
            indeterminate
            color="primary"
            class="mx-3"
          />
        </v-layout>

        <v-layout
          wrap
          v-else
        >
          <v-flex xs12>
            <FieldStr
              label="Assunto"
              v-model="metadata.assunto"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldGrau
              label="Grau do conteúdo"
              v-model="metadata.grau"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldTipo
              label="Tipo do Áudio"
              v-model="metadata.tipo"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldDate
              label="Data"
              v-model="metadata.data"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldOrador
              label="Orador"
              v-model="metadata.orador"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldOrador
              label="Dirigente"
              v-model="metadata.dirigente"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldOrador
              label="Autor"
              v-model="metadata.autor"
            />
          </v-flex>

          <v-flex
            xs12
            sm8
          >
            <FieldCore
              icon=""
              hide-details
              label="Núcleo"
              v-model="metadata.nucleo"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldSessao
              label="Sessão"
              v-model="metadata.sessao"
            />
          </v-flex>

          <v-flex
            xs12
            sm8
          >
            <FieldWords
              label="Palavras Chaves"
              v-model="metadata.palavras"
              :rows="1"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldQualidade
              label="Qualidade"
              v-model="metadata.qualidade"
            />
          </v-flex>

          <v-flex
            xs12
            sm8
          >
            <FieldAlbum
              label="Álbum"
              v-model="metadata.album"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldPermissao
              label="Permissão"
              v-model="metadata.permissao"
            />
          </v-flex>

          <v-flex
            xs12
            sm8
          >
            <FieldText
              label="Mestre disse"
              v-model="metadata.mestreDisse"
              :rows="1"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
          >
            <FieldText
              label="Texto da Revisão"
              v-model="metadata.revisaoObs"
              :rows="1"
            />
          </v-flex>

          <v-flex xs12>
            <FieldText
              label="Resumo"
              v-model="metadata.resumo"
              :rows="1"
            />
          </v-flex>

          <v-flex xs12>
            <FieldText
              label="Transcrição"
              v-model="metadata.transcricao"
              :rows="1"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-divider />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          color="blue darken-1"
          text
          @click="cancelReview"
        >
          Voltar
        </v-btn>

        <v-btn
          outlined
          color="red lighten-2"
          v-if="isAdmin && !isLoading"
          @click="removeAudio"
        >
          Excluir<br>Áudio
        </v-btn>

        <v-btn
          v-if="!isExam && !isLoading"
          color="orange lighten-2"
          outlined
          @click="setExam"
        >
          Colocar<br>em Exame
        </v-btn>

        <v-btn
          outlined
          color="info"
          v-if="!isLoading"
          @click="saveAdjusts"
        >
          Apenas<br>salvar
        </v-btn>

        <v-btn
          outlined
          color="success"
          v-if="isReviewed || isInReview || isExam"
          :loading="isLoading"
          @click="saveAudio"
        >
          <span v-if="isReviewed">Aprovar<br>Liberar</span>
          <span v-if="isInReview">Salvar<br>Revisão</span>
          <span v-if="isExam">Colocar<br>em Revisão</span>
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>

import FieldTipo from '@/components/fields/FieldTipo'
import FieldGrau from '@/components/fields/FieldGrau'
import FieldCore from '@/components/fields/FieldCore'
import FieldStr from '@/components/fields/FieldStr'
import FieldText from '@/components/fields/FieldText'
import FieldDate from '@/components/fields/FieldDate'
import FieldWords from '@/components/fields/FieldWords'
import HeaderCard from '@/components/HeaderCard'
import FieldAlbum from '@/components/fields/FieldAlbum'
import FieldOrador from '@/components/fields/FieldOrador'
import FieldSessao from '@/components/fields/FieldSessao'
import FieldPermissao from '@/components/fields/FieldPermissao'
import FieldQualidade from '@/components/fields/FieldQualidade'

import { formatSecs, getSession } from '@/utils'
import { mapState } from 'vuex'
import {
  STATUS_SAVED,
  STATUS_REJECT,
  STATUS_LOADING,

  ACTION_AUDIO_SAVE,
  ACTION_AUDIO_LOAD_API,
  ACTION_AUDIO_REMOVE,

  CODE_AUDIOS_EXAM,
  CODE_AUDIOS_APPROVED,
  CODE_AUDIOS_APPROVING,
  CODE_AUDIOS_FIRST_REVIEW,
  CODE_AUDIOS_WITHOUT_REVIEW
} from '@/constants'

export default {
  components: {
    HeaderCard,

    FieldStr,
    FieldDate,
    FieldGrau,
    FieldText,
    FieldTipo,
    FieldCore,
    FieldWords,
    FieldSessao,
    FieldOrador,
    FieldPermissao,

    FieldAlbum,
    FieldQualidade
  },

  data () {
    return {
      isAdmin: false,
      metadata: {},
      showControls: false
    }
  },

  computed: {
    revisaoStatus () {
      if (!this.metadata.revisaoStatus) return 0
      return Number(this.metadata.revisaoStatus)
    },

    isExam () {
      return CODE_AUDIOS_EXAM === this.revisaoStatus
    },

    isReviewed () {
      return CODE_AUDIOS_APPROVING === this.revisaoStatus
    },

    isInReview () {
      return this.isWithoutReview || this.isFirstReview
    },

    isWithoutReview () {
      return CODE_AUDIOS_WITHOUT_REVIEW === this.revisaoStatus
    },

    isFirstReview () {
      return CODE_AUDIOS_FIRST_REVIEW === this.revisaoStatus
    },

    ...mapState({
      review: ({ searchAudios }) => {
        const review = { ...searchAudios.reviewData }
        if (!review.id) return {}
        review.duracao = formatSecs(review.duracao)
        return review
      },

      wasSaved: ({ searchAudios }) => searchAudios.reviewStatus === STATUS_SAVED,
      wasReject: ({ searchAudios }) => searchAudios.reviewStatus === STATUS_REJECT,
      isLoading: ({ searchAudios }) => searchAudios.reviewStatus === STATUS_LOADING
    })
  },

  mounted () {
    this.$store.dispatch(ACTION_AUDIO_LOAD_API, this.$route.params.id)
    this.loadSession()
  },

  methods: {
    loadSession () {
      getSession().then((session) => {
        this.isAdmin = Boolean(session?.administrador)
        this.showControls = Boolean(session?.supervisor || session?.revisor || session?.administrador)
      })
    },

    removeAudio () {
      this.$store.dispatch(ACTION_AUDIO_REMOVE, this.metadata.id)
    },

    saveAdjusts () {
      this.$store.dispatch(ACTION_AUDIO_SAVE, { ...this.metadata, justSave: true })
    },

    saveAudio () {
      if (this.isLoading) return

      let revisaoStatus = this.metadata.revisaoStatus

      if (this.isExam) revisaoStatus = CODE_AUDIOS_FIRST_REVIEW
      if (this.isReviewed) revisaoStatus = CODE_AUDIOS_APPROVED
      if (this.isFirstReview) revisaoStatus = CODE_AUDIOS_APPROVING
      if (this.isWithoutReview) revisaoStatus = CODE_AUDIOS_FIRST_REVIEW

      this.$store.dispatch(ACTION_AUDIO_SAVE, { ...this.metadata, revisaoStatus })
    },

    setExam () {
      if (this.isLoading) return
      this.$store.dispatch(ACTION_AUDIO_SAVE, { ...this.metadata, revisaoStatus: CODE_AUDIOS_EXAM })
    },

    cancelReview () {
      this.$router.go(-1)
    }
  },

  watch: {
    wasSaved (saved) {
      saved && this.$router.go(-1)
    },

    wasReject (rejected) {
      rejected && this.$router.go(-1)
    },

    review (review) {
      this.metadata = saveFields.reduce((obj, field) => ({ ...obj, [field]: review[field] || null }), {})
    }
  }
}

const saveFields = [
  'id',
  'assunto',
  'resumo',
  'palavras',
  'revisaoObs',
  'sessao',
  'data',
  'nucleo',
  'autor',
  'dirigente',
  'orador',
  'revisaoStatus',
  'tipo',
  'grau',
  'mestreDisse',
  'transcricao',
  'qualidade',
  'permissao',
  'album'
]

</script>
